import Intro from "./components/Sections/Intro";
import Contact from "./components/Sections/Contact";
import Petice from "./components/Sections/Petice";
import Events from "./components/Sections/Events";
import Projekt from "./components/Sections/Projekt";
import { useLenis } from '@studio-freight/react-lenis';
import { Helmet } from 'react-helmet-async';
import Layout from "./Layout";
import Galerie from "./components/Sections/Galerie";
import Sluzby from "./components/Sections/Sluzby";
import Elearning from "./components/Sections/Elearning";

const Home = () => {
  const lenis = useLenis();
  const scroll = (hash) => {
    lenis.scrollTo(document.getElementById(hash));
  }
  return (
    <Layout>
      <Helmet>
        <title>Nehoda nanečisto</title>
        <meta name="description" content="Putovní VR projekt o dopravní (ne)bezpečnosti. Zažijte zkušenost dopravní nehody v bezpečném prostředí z pohledu chodce nebo řidiče." />

        <meta property="og:title" content="Nehoda nanečisto" />
        <meta property="og:description" content="Putovní VR projekt o dopravní (ne)bezpečnosti. Zažijte zkušenost dopravní nehody v bezpečném prostředí z pohledu chodce nebo řidiče." />
        <meta property="og:site_name" content="Nehoda nanečisto" />

        <meta name="twitter:title" content="Nehoda nanečisto" />
        <meta name="twitter:description" content="Putovní VR projekt o dopravní (ne)bezpečnosti. Zažijte zkušenost dopravní nehody v bezpečném prostředí z pohledu chodce nebo řidiče." />
      </Helmet>
      <Intro scrollTo={scroll} />
      <Projekt />
      <Events />
      <Galerie />
      <Sluzby />
      <Elearning />
      <Petice />
      <Contact />
    </Layout>
  )
}

export default Home;
